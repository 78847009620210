import React from 'react';
import { graphql } from 'gatsby';
import { MailIcon } from '@heroicons/react/outline';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import SEO from 'components/SEO';
import Section from 'components/Section';
import useSiteMetadata from 'hooks/useSiteMetadata';

export default function ContactPage() {
  const { t } = useTranslation();

  const site = useSiteMetadata();
  const title = t('contact:meta.title');

  return (
    <>
      <SEO
        title={`${title} - ${site.title}`}
        description={t('contact:meta.desc')}
        keywords={title}
      />

      <Section>
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div className="px-4 sm:px-6 lg:col-span-2 lg:px-8 xl:pr-12 pb-10">
            <div className="max-w-lg mx-auto">
              <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                {title}
              </h1>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                {t('contact:meta.desc')}
              </p>
              <dl className="mt-8 text-base text-gray-500">
                <div className="mt-3">
                  <dt className="sr-only">{t('contact:email')}</dt>
                  <dd className="flex">
                    <MailIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">
                      <a href={`mailto:${site.email}`}>{site.email}</a>
                    </span>
                  </dd>
                </div>
              </dl>
              <p className="mt-6 text-base text-gray-500">
                <Trans
                  i18nKey="videoproc:contact_support"
                  components={[
                    <span className="font-bold" />,
                    <a
                      href="mailto:media@videoproc.com"
                      className="font-medium text-gray-700 underline"
                    />,
                  ]}
                />
              </p>
            </div>
          </div>

          <div className="px-4 sm:px-6 lg:col-span-3 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <form
                action={`https://formspree.io/${site.email}`}
                method="POST"
                className="grid grid-cols-1 gap-y-6"
              >
                <div>
                  <label htmlFor="email" className="sr-only">
                    {t('contact:email')}
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                    placeholder={t('contact:email')}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="subject" className="sr-only">
                    {t('contact:subject')}
                  </label>
                  <input
                    id="subject"
                    name="_subject"
                    type="text"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                    placeholder={t('contact:subject')}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="url" className="sr-only">
                    {t('contact:video_link')}
                  </label>
                  <input
                    type="text"
                    name="url"
                    id="url"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                    placeholder={t('contact:video_link')}
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    {t('contact:message')}
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                    placeholder={t('contact:message')}
                    defaultValue={''}
                    required
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    {t('contact:send_message')}
                  </button>
                </div>
              </form>

              <p className="mt-6 text-base text-gray-500">
                <Trans
                  i18nKey="contact:powered_by"
                  values={{ site: 'Formspree.io' }}
                  components={[
                    <a
                      href="https://formspree.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-medium text-gray-700 underline"
                    />,
                  ]}
                />
              </p>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "contact", "videoproc"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
